import { useContext, useEffect } from "react";
import { GameStateContext } from "../../../../GameStateProvider";
import { navigateOnStateChange } from "../../../../../store/navigation";
import { Phase } from "art-escapades-common";
import { useNavigate } from "react-router-dom";
import { getResults, getWinners } from "../../../../../lib/utils/results";
import { Stack, Typography } from "@mui/material";
import { SubmissionDisplay } from "../../../../../lib/SubmissionDisplay";

export const HostResultsPage = () => {
  const { gameState } = useContext(GameStateContext);
  const navigate = useNavigate();

  useEffect(() => {
    navigateOnStateChange(gameState, Phase.RESULTS, navigate);
  }, [gameState]);

  const results = gameState ? getResults(gameState) : null;
  const winnerIds = results ? getWinners(results) : null;
  const winnerId = winnerIds?.at(0);
  const winner = winnerId ? gameState?.players[winnerId] : null;
  const winnerVotes = results && winnerId ? results[winnerId] : null;

  return (
    <Stack
      alignItems="center"
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Typography variant="h3">winner!</Typography>
      <SubmissionDisplay src={winner?.submission} prompt={winner?.prompt} />
      {winnerVotes !== null && (
        <Typography variant="body1">
          this submission got {winnerVotes} votes!
        </Typography>
      )}
    </Stack>
  );
};
