import { EventType, Phase } from "art-escapades-common";
import { useWebSocketSend } from "../../../../../hooks/useWebSocketSend";
import { useNavigate } from "react-router-dom";
import { navigateOnStateChange } from "../../../../../store/navigation";
import { useContext, useEffect } from "react";
import { GameStateContext } from "../../../../GameStateProvider";
import { WebSocketContext } from "../../../../WebSocketProvider";
import { SubmissionDisplay } from "../../../../../lib/SubmissionDisplay";
import { Stack, Typography } from "@mui/material";
import { getResults, getWinners } from "../../../../../lib/utils/results";
import { Button } from "../../../../../lib";

export const ParticipantResultsPage = () => {
  const { gameState } = useContext(GameStateContext);
  const socket = useContext(WebSocketContext);
  const navigate = useNavigate();
  const send = useWebSocketSend();

  const handlePlayAgain = () => {
    send({ type: EventType.PLAY_AGAIN });
  };

  useEffect(() => {
    navigateOnStateChange(gameState, Phase.RESULTS, navigate);
  }, [gameState]);

  const leaderPlayAgainDisplay =
    socket.id === gameState?.leader ? (
      <Button onClick={handlePlayAgain}>play again!</Button>
    ) : null;

  const results = gameState ? getResults(gameState) : null;
  const winnerIds = results ? getWinners(results) : null;
  const winnerId = winnerIds?.at(0);
  const winner = winnerId ? gameState?.players[winnerId] : null;

  return (
    <Stack
      alignItems="center"
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Typography variant="h3">winner!</Typography>
      <SubmissionDisplay src={winner?.submission} prompt={winner?.prompt} />

      {leaderPlayAgainDisplay}
    </Stack>
  );
};
