import { GameState } from "art-escapades-common";

export interface Results {
  [playerId: string]: number;
}

export function getResults(gameState: GameState): Results {
  const results: Results = {};

  Object.keys(gameState?.players ?? {}).forEach((playerId) => {
    results[playerId] = Object.entries(gameState?.players ?? {}).filter(
      ([id, player]) => playerId === player.vote && id !== playerId
    ).length;
  });
  return results;
}

export function getWinners(results: Results): string[] {
  const maxVotes = Math.max(...Object.values(results));
  return Object.entries(results)
    .filter(([, votes]) => votes === maxVotes)
    .map(([playerId]) => playerId);
}
