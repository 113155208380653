import { GameState } from "art-escapades-common";

import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { GameStateContext } from "../components/GameStateProvider";

export function AllSubmissions(input: {
  gameState: GameState;
  onClick?: (playerId: string) => void;
}) {
  const { playerId, images } = useContext(GameStateContext);
  const playerCount = Object.entries(input.gameState?.players ?? {}).length;

  const playerVote =
    playerId && input.gameState?.players[playerId]
      ? input.gameState?.players[playerId].vote
      : null;

  const md =
    playerCount <= 2 ? 6 : playerCount <= 4 ? 4 : playerCount <= 6 ? 4 : 3;

  const imgSize = (md / 12) * 100;

  const imageUrls = Object.keys(images);
  const sortedPlayers = Object.entries(input.gameState?.players ?? {}).sort(
    ([, a], [, b]) => {
      let indexA = imageUrls.indexOf(a.submission ?? "");
      if (indexA === -1) indexA = Infinity;
      let indexB = imageUrls.indexOf(b.submission ?? "");
      if (indexB === -1) indexB = Infinity;

      if (indexA < indexB) {
        return -1;
      } else if (indexA > indexB) {
        return 1;
      } else {
        return 0;
      }
    }
  );

  return (
    <Box sx={{ height: "100%", overflow: "auto" }}>
      <Grid
        container
        spacing={2}
        justifyContent="space-evenly"
        alignItems="center"
      >
        {sortedPlayers.map(([playerId, player]) => (
          <Grid item md={md} key={playerId}>
            <Button
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textTransform: "none",
              }}
              onClick={
                input.onClick ? () => input.onClick!(playerId) : undefined
              }
              disabled={!input.onClick}
            >
              {player.submission ? (
                <img
                  src={player.submission}
                  alt={player.prompt}
                  style={{
                    maxWidth: `${imgSize}vh`,
                    maxHeight: `${imgSize}vh`,
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    boxSizing: "border-box",
                    border:
                      playerVote === playerId
                        ? "2px solid red"
                        : "2px solid transparent",
                  }}
                />
              ) : (
                <CircularProgress />
              )}
              <Typography variant="body2" color="text.secondary" mt={1}>
                {player.prompt}
              </Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
