import { NavigateFunction } from "react-router-dom";
import { GameState, Phase } from "art-escapades-common";

export const navigateOnStateChange = (
  gameState: GameState | null,
  phase: Phase,
  navigate: NavigateFunction
) => {
  if (gameState && gameState?.phase !== phase) {
    navigate(`./../${gameState?.phase}`, { relative: "path" });
  }
};
